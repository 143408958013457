<script>
  import Multiselect from 'vue-multiselect'
import Switches from "vue-switches";
import Swal from "sweetalert2";
export default {
    components:{Switches,Multiselect},
    props:['service','ODS','send','pos','estimated','disable','planB','period'],
    data(){
        return {
            active:false,
            addPersonel:false,
            addEQP:false,
            addMTR:false,
            fields:[{poste:"",qte:0,amount:0}],
            fieldsD:[],
            selectedProducts:[{product:"",qte:0,amount:0}],
            selectedMaterials:[{equipment:"",qte:0,amount:0}],
            selectedMTR:[{equipment:"",qte:0,amount:0}],
            selectedReal:[{realize:"",qte:0,amount:0}],
            selectedMaterialsD:[],
            selectedMTRD:[],
            PostesList:[],
            PostesListOrdering:[],
            EQPListOrdering:[],
            MTRListOrdering:[],
            ProductsList:[],
            EquipmentsList:[],
            MaterialsList:[],
            RH:[],
            postes:[],
            implementations:[],
            retrieveRH:false,
            retrieveEQP:false,
            AmountEquipments:0,
            AmountMTR:0,
            AmountReal:0,
            AmountPostes:0,
        }
    },
    
    mounted(){
        
            switch (this.pos) {
                case 'Entretien':
                    var keys = Object.keys(this.service[this.pos]);
                    keys.forEach(element=> {
                        if(element =='human'){
                            this.retrieveRH=true;
                            if(this.service[this.pos][element][0].quantity > 0){
                                this.fields = [];
                                this.fieldsD = [];
                                this.service[this.pos][element].forEach(x => {
                                    if(x.service.estimation_type == 'daily'){ this.PostesList.push(x.service); this.fieldsD.push({id:x.id,poste:x.service,qte:x.quantity,amount:x.amount})}
                                    else {this.PostesListOrdering.push(x.service);this.fields.push({id:x.id,poste:x.service,qte:x.quantity,amount:x.amount})}
                                })
                            }
                            else {
                                this.PostesList = this.service[this.pos][element].filter(element => (element.estimation_type == 'daily'));
                                this.PostesListOrdering = this.service[this.pos][element].filter(element => (element.estimation_type == 'when_ordering')); 
                                
                                this.PostesList.forEach(element => {
                                    this.fieldsD.push({
                                        id:element.id,
                                        poste:element,
                                        qte:element.estimated_qty,
                                        amount:parseInt(element.estimated_qty) * parseInt(element.unit_price),
                                    })
                                })
                            }

                            this.TotalAmount()
                        }
                        else if (element == 'equipment'){
                            this.retrieveEQP=true;
                            if(this.service[this.pos][element][0].quantity > 0){
                                this.selectedMaterials = [];
                                this.selectedMaterialsD = [];
                                this.service[this.pos][element].forEach(x => {
                                    if(x.service.estimation_type == 'daily'){this.selectedMaterialsD.push({id:x.id,equipment:x.service,qte:x.quantity,amount:x.amount});this.EquipmentsList.push(x.service)}
                                    else{this.selectedMaterials.push({id:x.id,equipment:x.service,qte:x.quantity,amount:x.amount});this.EQPListOrdering.push(x.service)}
                                })
                            }
                            else{
                                this.EquipmentsList = this.service[this.pos][element].filter(x => (x.estimation_type == 'daily'));
                                this.EQPListOrdering = this.service[this.pos][element].filter(x => (x.estimation_type == 'when_ordering'));

                                this.EquipmentsList.forEach(element => {
                                    this.selectedMaterialsD.push({
                                        id:element.id,
                                        equipment:element,
                                        qte:element.estimated_qty,
                                        amount:parseInt(element.estimated_qty) * parseInt(element.unit_price),
                                    })
                                })
                            } 
                        }
                        this.TotalAmount()
                    })
                    break;
                case 'Fournitures des produits phytosanitaires':
                    if(this.service[this.pos][0].quantity > 0){
                        this.service[this.pos].forEach(element => {
                            this.selectedProducts = [];
                            this.selectedProducts.push({id:element.id,product:element.product,qte:element.quantity,amount:element.amount})
                            this.ProductsList.push(element.product)
                        })
                    }
                    else this.ProductsList = this.service[this.pos];
                    this.TotalAmount()
                    break;
                
                case 'Matériel roulant':
                    if(this.service[this.pos][0].quantity > 0){
                        this.selectedMTR = [];
                        this.selectedMTRD = [];
                        this.service[this.pos].forEach(x => {
                            if(x.service.estimation_type == 'daily'){this.selectedMTRD.push({id:x.id,equipment:x.service,qte:x.quantity,amount:x.amount});this.MaterialsList.push(x.service)}
                            else{this.selectedMaterials.push({id:x.id,equipment:x.service,qte:x.quantity,amount:x.amount});this.MTRListOrdering.push(x.service)}
                        })
                    }
                    else{
                        this.MaterialsList = this.service[this.pos].filter(x => (x.estimation_type == 'daily'));
                        this.MTRListOrdering = this.service[this.pos].filter(x => (x.estimation_type == 'when_ordering'));

                        this.MaterialsList.forEach(element => {
                            this.selectedMTRD.push({
                                id:element.id,
                                equipment:element,
                                qte:element.estimated_qty,
                                amount:parseInt(element.estimated_qty) * parseInt(element.unit_price),
                            })
                        })
                        // this.TotalAmount()
                    } 
                    this.TotalAmount()
                    break;
                default:
                    if(this.service[this.pos][0].quantity > 0){
                        this.selectedReal = [];
                        this.service[this.pos].forEach((x,i) => {
                            this.selectedReal.push({id:x.id,realize:x.service,qte:x.quantity,amount:x.amount});
                            this.selectedReal[i].realize['uom']=x.uom;
                            this.implementations.push(x.service);
                        })
                    }
                    else this.implementations = this.service[this.pos];

                    this.TotalAmount()
                    
                    break;
            }
        
    },
    methods:{
        selectedPoste(poste){
            console.log(this.fields);
        },
        AddformData() {
            this.fields.push({selctedPoste:"",qte:"",amount:0});
        },
        AddformDataProduct() {
            this.selectedProducts.push({product:"",qte:"",amount:0});
        },
        AddformDataMat() {
            this.selectedMaterials.push({equipment:"",qte:"",amount:0});
        },
        AddformDataMTR() {
            this.selectedMTR.push({equipment:"",qte:"",amount:0});
        },
        AddformDataReal() {
            this.selectedReal.push({realize:"",qte:"",amount:0});
        },
        deleteRow(index) {
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de supprimer cette ligne ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.fields.splice(index, 1);
                }
            })
        },
        deleteProductRow(index) {
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de supprimer cette ligne ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.selectedProducts.splice(index, 1);
                }
            })
        },
        deleteMatRow(index) {
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de supprimer cette ligne ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.selectedMaterials.splice(index, 1);
                }
            })
            
        },
        deleteMTRRow(index) {
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de supprimer cette ligne ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.selectedMTR.splice(index, 1);
                }
            })
            
        },
        deleteRealRow(index) {
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de supprimer cette ligne ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.selectedReal.splice(index, 1);
                }
            })
            
        },
        getAmount(){
            if(this.pos == 'Entretien'){
                if (this.retrieveRH){
                    this.fieldsD.concat(this.fields);
                    console.log(this.fieldsD);
                    this.fieldsD.forEach(element => {
                       if(element.id) element.amount = parseInt(element.poste.unit_price) * parseInt(element.qte)
                    })
                    // console.log(this.fieldsD);
                }
                if(this.retrieveEQP){
                    this.selectedMaterialsD.concat(this.selectedMaterials);
                    this.selectedMaterialsD.forEach(element => {
                        element.amount = parseInt(element.equipment.unit_price) * parseInt(element.qte)
                    })
                    // console.log(this.fields);
                }
            }
            if(this.pos == 'Fournitures des produits phytosanitaires'){
                 this.selectedProducts.forEach(element => {
                     element.amount = parseInt(element.product.unit_price) * parseInt(element.qte)
                 })
            }
            if(this.pos == 'Matériel roulant'){
                this.selectedMTRD.concat(this.selectedMTR);
                this.selectedMTRD.forEach(element => {
                    element.amount = parseInt(element.equipment.unit_price) * parseInt(element.qte)
                })
            }
            if(this.pos == 'Réalisation'){
                // this.selectedReal.concat(this.selectedMTR);
                this.selectedReal.forEach(element => {
                    element.amount = parseInt(element.realize.unit_price) * parseInt(element.qte)
                })
            }
        },
        TotalAmount(){
            this.getAmount();
            var total =0;
            if(this.pos == 'Entretien'){
                if(this.retrieveEQP){
                    total = 0;
                    total += this.selectedMaterialsD.reduce(function(carry,item){
                        return carry+parseInt(item.amount);
                    },0)
                    if(total > 0){
                        this.AmountEquipments = total;
                        
                    }
                }
                else if (this.retrieveRH){
                    total =0;
                    total += this.fieldsD.reduce(function(carry,item){
                        return carry+parseInt(item.amount);
                    },0)

                    if(total > 0){
                        this.AmountPostes = total;
                        
                    }
                }
                this.$emit('getvalues',{human:this.fieldsD,equipment:this.selectedMaterialsD})
                this.$emit('getvaluesEmitted',true)
                 
            }

            if(this.pos == 'Fournitures des produits phytosanitaires'){
                 total = this.selectedProducts.reduce(function(carry,item){
                    return carry+parseInt(item.amount);
                },0)

                if(total > 0){
                    this.$emit('getvalues',this.selectedProducts);
                    this.$emit('getvaluesEmitted',true);
                }
            }

            if(this.pos == 'Matériel roulant'){
                total = 0;
                total += this.selectedMTRD.reduce(function(carry,item){
                    return carry+parseInt(item.amount);
                },0)
                if(total > 0){
                    this.AmountMTR = total;
                    this.$emit('getvalues',this.selectedMTRD);
                    this.$emit('getvaluesEmitted',true);
                }
            }
            if(this.pos == 'Réalisation'){
                total = 0;
                total += this.selectedReal.reduce(function(carry,item){
                    return carry+parseInt(item.amount);
                },0)
                if(total > 0){
                    this.AmountReal = total;
                    this.$emit('getvalues',this.selectedReal);
                    this.$emit('getvaluesEmitted',true);
                }
            }
            return total;
        },
        customLabel({job_title}){
            return `${job_title.designation}` 
        },
        customLabelEQP({equipments}){
            return `${equipments.designation}` ;
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return;
            }
        }
    }
    
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="accordion mb-2" role="tablist">
                <b-card no-body class="mb-1" v-if="(pos == 'Entretien')">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Ressources Humaines (Entretien) </div>
                            
                            <div class="col text-right">
                                <span v-if="canAny(['validate_gs_ods','confirm_gs_ods'])">Montant : {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(AmountPostes)}}</span>
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <div class="p-3">

                                <div class="row">
                                    <div class="col">
                                    <label for="">Faire une demande</label><br>
                                    <switches v-model="addPersonel" type-bold="true" color="info" class="ms-1"></switches>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                    <b-card title="Personnel Contractuels">
                                        <b-card-text>
                                        <table class="table mt-2">
                                            <thead>
                                                <th>Poste</th>
                                                <th class="text-center">à la demander</th>
                                                <th class="text-center">Salaire Journalier</th>
                                                <th class="text-right">Nombres</th>
                                            </thead>

                                            <tr v-for="(poste,i) in PostesList" :key="i">
                                                <th> {{poste.job_title.designation}} </th>
                                                <td v-if="poste.estimation_type == 'when_ordering'" class="text-success text-center"><i  class="fas fa-check-circle text-success"></i></td>
                                                <td v-else class="text-center"><i class="fas fa-times-circle text-danger "></i></td>
                                                <td class="text-center"> {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(poste.unit_price)}} </td>
                                                <td class="text-right"> {{poste.estimated_qty}} </td>
                                            </tr>
                                        </table>
                                        </b-card-text>
                                    </b-card>
                                    </div>
                                    <div v-if="addPersonel" class="col-lg-6">
                                    <b-card title="Personnel à la demande">
                                        <div class="row" v-for="(field,i) in fields" :key="i">
                                            <div class="col-md-8">
                                                <label for="">Poste à Demander</label>
                                                
                                                <multiselect 
                                                    v-model="fields[i].poste" 
                                                    class="here" 
                                                    openDirection="below"  
                                                    deselect-label="" 
                                                    track-by="id" 
                                                    label="name" 
                                                    :custom-label="customLabel" 
                                                    :show-labels="false"
                                                    placeholder="" 
                                                    :options="PostesListOrdering" 
                                                    :searchable="false" 
                                                    :allow-empty="false">

                                                    <!-- <template slot="singleLabel" slot-scope="props">{{ props.option.job_title.designation }}</template> -->
                                                </multiselect>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="">Nombres </label>
                                                <input class="form-control" type="number" min="0" placeholder="20" v-model="fields[i].qte" @keypress="isNumber($event)" @input="TotalAmount">
                                            </div>
                                            <div class="col-md-2  mt-4"> <a class="btn text-danger" @click="deleteRow(i)"> <i class="far fa-trash-alt font-size-24"></i> </a> </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col text-right">
                                                <button class="btn btn-info" type="button" @click="AddformData"><i class="fas fa-plus-circle"></i> Ajouter</button>
                                            </div>
                                        </div>
                                        
                                    </b-card>
                                    </div>
                                </div>

                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1"  v-if="pos == 'Entretien' && retrieveEQP==1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Ressources Materiels</div>
                            <div class="col text-right">
                                <span v-if="canAny(['validate_gs_ods','confirm_gs_ods'])">Montant : {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(AmountEquipments)}}</span>
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <div class="row">
                                <div class="col">
                                <label for="">Faire une demande</label><br>
                                <switches v-model="addEQP" type-bold="true" color="info" class="ms-1"></switches>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-card title="Materiels Contractuels">
                                        <b-card-text>
                                        <table class="table mt-2">
                                            <thead>
                                                <th>Materiels</th>
                                                <th class="text-center">à la demander ?</th>
                                                <th class="text-right">Nombres</th>
                                            </thead>

                                            <tr v-for="(materiel,i) in EquipmentsList" :key="i">
                                                <th> {{materiel.equipments.name}} </th>
                                                <td v-if="materiel.estimation_type == 'when_ordering'" class="text-success text-center"><i  class="fas fa-check-circle text-success"></i></td>
                                                <td v-else class="text-center"><i class="fas fa-times-circle text-danger "></i></td>
                                                <td class="text-right"> {{materiel.estimated_qty}} </td>
                                            </tr>
                                        </table>
                                        </b-card-text>
                                    </b-card>
                                </div>
                                <div class="col-md-6" v-if="addEQP">
                                    <b-card title="Materiels à demander">
                                        <div class="row mt-3" v-for="(selectedMaterial,i) in selectedMaterials" :key="i">
                                            <div class="col-md-8">
                                                <label for="">Liste Materiels</label>
                                                
                                                <multiselect 
                                                    class="here" 
                                                    placeholder="" 
                                                    v-model="selectedMaterial.equipment" 
                                                    openDirection="below" 
                                                    :maxHeight="500" 
                                                    :options="EQPListOrdering" 
                                                    :multiple="false"  
                                                    :group-select="false"  
                                                    track-by="id" 
                                                    :custom-label="customLabelEQP" 
                                                >
                                                    <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                                                </multiselect>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="">Quantité </label>
                                                <input class="form-control" type="number" placeholder="20" v-model="selectedMaterial.qte"  @keypress="isNumber($event)" @input="TotalAmount">
                                            </div>
                                            <div class="col-md-2  mt-4"> <a class="btn text-danger" @click="deleteMatRow(i)"> <i class="far fa-trash-alt font-size-24"></i> </a> </div>
                                            
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col text-right">
                                                <button class="btn btn-info" type="button" @click="AddformDataMat"><i class="fas fa-plus-circle"></i> Ajouter</button>
                                            </div>
                                        </div>
                                    </b-card>
                                </div> 
                            </div>
                                
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" v-if="pos == 'Fournitures des produits phytosanitaires'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Produits</div>
                            <div class="col text-right">
                                <span v-if="canAny(['validate_gs_ods','confirm_gs_ods'])">Montant : {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(TotalAmount())}}</span>
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg" style="heigth:200px">
                            <div class="row" v-for="(selectedProduct,i) in selectedProducts" :key="i">
                                <div class="col-md-4">
                                <label for="">Liste Produits</label>
                                
                                <multiselect class="here" v-model="selectedProduct.product" openDirection="below" :maxHeight="500" :options="ProductsList" :multiple="false" :group-select="false" placeholder="Type to search" track-by="id" label="name">
                                    <span slot="noResult">Aucun Resultat trouvé.</span>
                                    <!-- <template slot="singleLabel" slot-scope="{ product }"><strong>{{ product.products.name }}</strong></template> -->
                                </multiselect>
                                </div>
                                <!-- <div class="col-md-2" v-if="selectedProduct.product">
                                    <label for="">Quantité Prévisionnel</label>
                                    <input class="form-control" type="number" disabled  v-model="selectedProduct.product.estimated_qty">
                                </div> -->
                                <div class="col-md-2" v-if="selectedProduct.product.uom">
                                    <label for="">Unité</label>
                                    <input class="form-control" type="text" disabled  v-model="selectedProduct.product.uom.designation">
                                </div>
                                <div class="col-md-2">
                                <label for="">Quantité </label>
                                <input class="form-control" type="number" placeholder="20" v-model="selectedProduct.qte"  @keypress="isNumber($event)" @input="TotalAmount">
                                </div>
                                <div class="col-md-2" v-if="selectedProduct.product">
                                    <label for="">Délai</label>
                                    <input class="form-control" type="text" disabled  v-model="period" readonly>
                                </div>
                                <div class="col-md-2  mt-4"> <a class="btn text-danger" @click="deleteProductRow(i)"> <i class="far fa-trash-alt font-size-24"></i> </a> </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col text-right">
                                    <button class="btn btn-info" type="button" @click="AddformDataProduct"><i class="fas fa-plus-circle"></i> Ajouter</button>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1"  v-if="pos == 'Matériel roulant'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i>Matériel roulant</div>
                            <div class="col text-right">
                                <span v-if="canAny(['validate_gs_ods','confirm_gs_ods'])">Montant : {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(AmountMTR)}}</span>
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <div class="row">
                                <div class="col">
                                <label for="">Faire une demande</label><br>
                                <switches v-model="addMTR" type-bold="true" color="info" class="ms-1"></switches>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-card title="Materiels Contractuels">
                                        <b-card-text>
                                        <table class="table mt-2">
                                            <thead>
                                                <th>Materiels</th>
                                                <th class="text-center">à la demander ?</th>
                                                <th class="text-right">Nombres</th>
                                            </thead>

                                            <tr v-for="(materiel,i) in MaterialsList" :key="i">
                                                <th> {{materiel.equipments.name}} </th>
                                                <td v-if="materiel.estimation_type == 'when_ordering'" class="text-success text-center"><i  class="fas fa-check-circle text-success"></i></td>
                                                <td v-else class="text-center"><i class="fas fa-times-circle text-danger "></i></td>
                                                <td class="text-right"> {{materiel.estimated_qty}} </td>
                                            </tr>
                                        </table>
                                        </b-card-text>
                                    </b-card>
                                </div>
                                <div class="col-md-6" v-if="addMTR">
                                    <b-card title="Materiels à demander">
                                        <div class="row mt-3" v-for="(selectedMaterial,i) in selectedMTR" :key="i">
                                            <div class="col-md-8">
                                                <label for="">Liste Materiels</label>
                                                
                                                <multiselect 
                                                    class="here" 
                                                    placeholder="" 
                                                    v-model="selectedMaterial.equipment" 
                                                    openDirection="below" 
                                                    :maxHeight="500" 
                                                    :options="MTRListOrdering" 
                                                    :multiple="false"  
                                                    :group-select="false"  
                                                    track-by="id" 
                                                    :custom-label="customLabelEQP" 
                                                >
                                                    <span slot="noResult">Aucun Résultat Trouvé.</span>
                                                </multiselect>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="">Quantité </label>
                                                <input class="form-control" type="number" placeholder="20" v-model="selectedMaterial.qte"  @keypress="isNumber($event)" @input="TotalAmount">
                                            </div>
                                            <div class="col-md-2  mt-4"> <a class="btn text-danger" @click="deleteMTRRow(i)"> <i class="far fa-trash-alt font-size-24"></i> </a> </div>
                                            
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col text-right">
                                                <button class="btn btn-info" type="button" @click="AddformDataMTR"><i class="fas fa-plus-circle"></i> Ajouter</button>
                                            </div>
                                        </div>
                                    </b-card>
                                </div> 
                            </div>
                                
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" v-if="pos == 'Réalisation'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i>Réalisation</div>
                            <div class="col text-right">
                                <span v-if="canAny(['validate_gs_ods','confirm_gs_ods'])">Montant : {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(AmountReal)}}</span>
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg" style="heigth:200px">
                            <div class="row" v-for="(selectedProduct,i) in selectedReal" :key="i">
                                <div class="col-md-4">
                                <label for="">Liste des Réalisations</label>
                                <multiselect class="here" v-model="selectedProduct.realize" openDirection="below" :maxHeight="500" :options="implementations" :multiple="false" :group-select="false" placeholder="Type to search" track-by="id" label="name">
                                    <span slot="noResult">Aucun Resultat trouvé.</span>
                                </multiselect>
                                </div>
                                
                                <div class="col-md-2" v-if="selectedProduct.realize.uom">
                                    <label for="">Unité</label>
                                    <input class="form-control" type="text" disabled  v-model="selectedProduct.realize.uom.designation">
                                </div>
                                <div class="col-md-2">
                                <label for="">Quantité </label>
                                <input class="form-control" type="number" placeholder="20" v-model="selectedProduct.qte"  @keypress="isNumber($event)" @input="TotalAmount()">
                                </div>
                                <div class="col-md-2" v-if="selectedProduct.realize">
                                    <label for="">Délai</label>
                                    <input class="form-control" type="text" disabled  v-model="period" readonly>
                                </div>
                                <div class="col-md-2  mt-4"> <a class="btn text-danger" @click="deleteRealRow(i)"> <i class="far fa-trash-alt font-size-24"></i> </a> </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col text-right">
                                    <button class="btn btn-info" type="button" @click="AddformDataReal"><i class="fas fa-plus-circle"></i> Ajouter</button>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </div>
</template>
<style>
.here .multiselect__content-wrapper{
    position:relative !important;
    max-height: 300px !important;
}
</style>